

import S1 from "@/pages/S1";
import G1 from "@/pages/G1";
import M1 from "@/pages/M1";

import E404 from "@/pages/E404";
import Honors from "@/pages/Honors";
import Flow from "@/pages/Flow";

import Surl from "@/pages/Surl";
import S1menu from "@/pages/S1menu";
import Gurl from "@/pages/Gurl";
import G1menu from "@/pages/G1menu";
import Aphone from "@/pages/Ae/Aphone";

import { createBrowserRouter } from 'react-router-dom';


const router = createBrowserRouter([
    {
        path: "/",
        element: <M1></M1>,
    }
    ,
    {
        path: "SD",
        element: <S1></S1>,
        children: [
            {
                path: "index",
                element: <S1menu />,
            },
            //honors
            {
                path: "honors",
                element: <Honors />
            },
            //FLOW
            {
                path: "flow",
                element: <Flow />
            },
            {
                path: "*",
                element: <E404 />
            }

        ],
    },
    {
        path: "GD",
        element: <G1></G1>,
        children: [
            {
                path: "index",
                element: <G1menu />,
            },
            {
                path: "honors",
                element: <Honors />
            },
            {
                path: "*",
                element: <E404 />
            }

        ],
    },
    {
        path: "/G/:dbcode",
        element: <Gurl />
    },
    {
        path: "/S/:dbcode",
        element: <Surl />
    },
    {
        path: "/p/:ename",
        element: <Aphone />
    },
])
export default router