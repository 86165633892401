import React, { useRef, useState } from 'react';
import { useParams } from "react-router-dom";
import { Tabs, List, Avatar } from 'antd-mobile'
import { Typography, Table, Input, Space, Button } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { PhoneFill } from 'antd-mobile-icons'

import Highlighter from 'react-highlight-words';
const { Paragraph, Title } = Typography;


const data = [
  { key: '1', qname: '广东省东莞市', name: '罗雄峰', phone: '15007690133', },
  { key: '2', qname: '广州市增城区', name: '黄展发', phone: '13434240158', },
  { key: '3', qname: '广州市黄埔区', name: '彭建满', phone: '18902335126', },
  { key: '4', qname: '惠州市惠城区', name: '罗晓锋', phone: '13928300225', },
  { key: '5', qname: '广州市增城区', name: '肖永', phone: '13560218007', },
  { key: '6', qname: '佛山市顺德区', name: '高文', phone: '13531439848', },
  { key: '7', qname: '广州市天河区', name: '彭胜', phone: '13500007158', },
  { key: '8', qname: '广州市番禺区', name: '吴双', phone: '13711016258', },
  { key: '9', qname: '中山东区', name: '罗季坤', phone: '13528283141', },
  { key: '10', qname: '中山市横栏镇', name: '罗广威', phone: '18676171597', },
  { key: '11', qname: '江门市江海区', name: '李湘林', phone: '13672838514', },
  { key: '12', qname: '珠海市斗门区', name: '叶晴', phone: '13544926559', },
  { key: '13', qname: '柳州市鱼峰区', name: '李勇齐', phone: '15177725110', },
  { key: '14', qname: '惠州市大亚湾西区', name: '彭双志', phone: '18923631795', },
  { key: '15', qname: '广西钦州', name: '王健', phone: '14797771155', },
  { key: '16', qname: '湖南怀化', name: '李坤', phone: '13147330888', },
  { key: '17', qname: '广西壮族自治区', name: '兰阳', phone: '15578765776', },
  { key: '18', qname: '广西贵港', name: '李勇齐', phone: '19977243110', },
  { key: '19', qname: '湖南岳阳', name: '李怡泓', phone: '18073079368', },
  { key: '20', qname: '贵州凯里', name: '吴鹏', phone: ' 15186832882', },
  { key: '21', qname: '贵州铜仁', name: '何海军', phone: '18286612002', },
  { key: '22', qname: '贵州毕节', name: '吴勇', phone: '15902587277', },
  { key: '23', qname: '贵州都匀', name: '谭京', phone: '15117833006', },
  { key: '24', qname: '贵州贵阳', name: '吴鹏', phone: '15186832882', },
  { key: '25', qname: '广东佛山', name: '邓宋', phone: '13717468613', },
  { key: '26', qname: '广东肇庆', name: '万俭', phone: '15119867475', },
  { key: '27', qname: '广西南宁', name: '刘勇', phone: '13507882181', },
  { key: '28', qname: '广西梧州', name: '谢志归', phone: '13878463678', },
  { key: '29', qname: '广西百色', name: '杨斌', phone: '18177609388', },
  { key: '30', qname: '广西贺州', name: '文松', phone: '18074997776', },
  { key: '31', qname: '甘肃兰州', name: '刘旦', phone: '15117284885', },
  { key: '32', qname: '湖南衡阳', name: '呙霞', phone: '13007340092', },
  { key: '33', qname: '湖南湘西', name: '刘自强', phone: '19374368405', },
  { key: '34', qname: '湖南娄底', name: '何鹏', phone: '15399894211', },
  { key: '35', qname: '陕西西安', name: '柴江飞', phone: '15291681969', },
  { key: '36', qname: '广西崇左', name: '罗颖玲', phone: '18807812408', },
  { key: '37', qname: '河南郑州', name: '单福超', phone: '13903826722', },
  { key: '38', qname: '云南曲靖', name: '王文勃', phone: '15188128412', },
];

const Aphone = () => {

  const params = useParams()
  console.log(params.ename)
  document.title = '树医生环艺美集团'


  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`检索内容`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            检索
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            重置
          </Button>


        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    filterDropdownProps: {
      onOpenChange(open) {
        if (open) {
          setTimeout(() => searchInput.current?.select(), 100);
        }
      },
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });
  const columns = [
    {
      title: '区域',
      dataIndex: 'qname',
      key: 'qname',
      ...getColumnSearchProps('qname'),
    },
    {
      title: '联系人',
      dataIndex: 'name',
      key: 'name',
      ...getColumnSearchProps('name'),
      render: (_, e) => (<div><PhoneFill color='green' onClick={() => { window.location.href = `tel:${e.phone}` }} />    {e.name}</div>
      ),


    },

  ];


  return <>
    <List>
      <List.Item
        prefix={<Avatar src='http://d.ddspp.com:30123/1000_1736140158#sc' />}
      // description='Deserunt dolor ea eaque eos'
      >
        树医生环艺美集团
      </List.Item>
    </List>
    <Tabs defaultActiveKey='1'>
      <Tabs.Tab title='关于我们' key='1'>
        <Paragraph style={{ textIndent: '2em' }}>
          树医生环艺美集团是一家集园林养护、机械工具销售、古树复壮、园林绿化工程养护等多领域服务于一体的综合性企业。我们以人为本，以技术为支撑，致力于为客户提供全面的园林解决方案。
          我们以园林植保技术为核心，组建了一支专业、高效的团队。与市政园林单位、物业小区、工厂及园林工程公司等建立了深入的合作关系，提供专业实用的技术方案和质量可靠的产品。遍布广东、广西、贵州、河南、湖南、甘肃、陕西等省份，旗下40余家子公司。我们的服务网络遍布全国，确保每一位客户都能享受到及时、专业的服务。为您的园林养护提供一站式服务。
        </Paragraph>
        <Title level={4}>我们的产品和服务：</Title>
        <Title level={5}>一、高效低成本园林养护品</Title>
        <Paragraph>1、园林植物疑难害虫：（抗性蚧壳虫、天牛吉丁虫小蠹等蛀干害虫）；</Paragraph>
        <Paragraph>2、抗性病害：(抗性白粉病、疫病、腐烂病、流胶病、根腐病等）；</Paragraph>
        <Paragraph>3、园林专用除草剂（莎草科除草剂、禾本科除草剂、长效土壤封闭剂）；</Paragraph>
        <Paragraph>4、园林专用肥料（颗粒肥、液体冲施肥缓释肥、精制有机肥、叶面肥）；</Paragraph>
        <Paragraph>5、大树移植系列产品（生根剂、吊针液）；古树弱树复壮产品；</Paragraph>
        <Paragraph>6、杨柳飞絮抑制剂、芒果去果剂等高效低毒专用农药。</Paragraph>


        <Title level={5}>二、园林专用机械资材</Title>

        <Paragraph>  1、高效进口、国产园林机械（剪草机、油锯、打孔机、打药机、粉碎机、绿篱机、弥雾机、雾炮机、微耕机）；水泵；锂电池系列园林机械；</Paragraph>
        <Paragraph>2、园林工具（各类枝剪、绿篱剪、高枝剪）；园林机械专用机油；耐用水管；颗粒施肥机；液体施肥枪；</Paragraph>
        <Paragraph>3、杀虫灯；粘虫板；喷灌喷头、景观喷头、雾森喷头、智能浇水系统；</Paragraph>
        <Paragraph>4、控根器；美植袋（无纺布袋）；营养钵；花盆；树木支撑杆；除草布（园艺地布）；遮阴网；</Paragraph>
        <Paragraph> 5、草种；花种。</Paragraph>
        <Title level={5}>三、专业园林技术服务</Title>

        <Paragraph style={{ textIndent: '2em' }}>树医生为广大园林朋友提供专业实用的园林技术培训，让您上午培训，下午即可在工作中有用。为战略合作客户每个季度可以提供专业技术培训。古树名木救治；园林绿地专业养护等。园林专业图书(如：中国园林害虫、园林植物病虫害防治图鉴、树木医生手册、中国景观植物应用大全等。</Paragraph>
      </Tabs.Tab>
      <Tabs.Tab title='服务网点' key='2'>

        <Table columns={columns} dataSource={data} pagination={false} />
      </Tabs.Tab>

    </Tabs>






  </>
}

export default Aphone;