import React from 'react'
import { TabBar } from 'antd-mobile'
import {  ContentOutline } from 'antd-mobile-icons'

import {IdcardOutlined} from '@ant-design/icons';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import './index.scss';




const S1 = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = location


    const setRouteActive = (value) => {
        navigate('/SD' + value)

    }

    const tabs = [
        {
            key: '/index',
            title: '首页',
            icon: <ContentOutline />,
        },
        // {
        //     key: '/source',
        //     title: '溯源',
        //     icon: <TravelOutline />,  
        // },
        // {
        //     key: '/flow',
        //     title: '流转记录',
        //     icon: <TruckOutlined />,
        // },
        {
            key: '/honors',
            title: '资质荣誉',
            icon: <IdcardOutlined />,
        },

    ]

    return (
        <>
            <Outlet></Outlet>

           {
             false && <TabBar activeKey={pathname} onChange={value => setRouteActive(value)} className='foot' >
                {tabs.map(item => (
                    <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
                ))}
            </TabBar>
           } 
        </>





    )
}

export default S1;